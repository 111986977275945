@import "~antd/dist/antd.css";

html,
body {
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
}
.content-body {
  height: 100%;
}
.bold {
  font-weight: bold;
}
a {
  text-decoration: none !important;
  color: #8a34cd;
}
.ant-btn-primary {
  background: #8a34cd;
  border-color: #8a34cd;
}
.ant-btn-primary:hover,
.ant-btn-primary:active {
  background: #a05cd7;
  border-color: #8a34cd;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #8a34cd;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #8a34cd;
}
.settings-saved-modal {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-confirm-btns {
    display: none;
  }
}
